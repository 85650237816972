<template>
  <div class="security">
    <VOverlay v-if="_updatingStatus === requestStatus.PENDING">
      <VLoader/>
    </VOverlay>
    <!--    <div class="security__main mb-2">
          <div class="security__two-factor mb-3">
            <div class="col-9">
              <span class="text text-semibold">Two factor authentication</span>
            </div>
            <div class="col-3">
              <v-button
                  type="orange"
                  :disabled="true"
                  class="security__edit-button"
                  @click="$store.commit('profile/SET_IS_EDITING', true)"
              >
                Enable
              </v-button>
            </div>
          </div>
        </div>-->
    <div class="security__main">
      <div class="text text-semibold mb-3">Change password</div>
      <form class="security-edit__form pt-2" @submit.prevent="submitForm">
        <div class="row mb-3">
          <div class="col">
            <FieldText
                v-model="form.old_password"
                type="password"
                labelText="Old Password*"
                :validation="$v.form.old_password.$error"
                class="mb-2"
            />
            <FieldText
                v-model="form.password"
                type="password"
                labelText="New Password*"
                :validation="$v.form.password.$error"
                class="mb-2"
            />
            <FieldText
                v-model="form.password_confirmation"
                type="password"
                labelText="Confirm New Password*"
                :validation="$v.form.password_confirmation.$error"
                class="mb-2"
            />
            <div class="security__button-space ">
              <v-button
                  type="orange-outline"
                  class="security__form-btn mt-3"
                  @click="submitForm"
              >
                Save password
              </v-button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import FieldText from "@/js/components/common/form/FieldText";
import { requestStatus, toasted } from '@/js/utils/constants';
import { minLength, required } from "vuelidate/lib/validators";

export default {
  name: "Security",
  components: { FieldText },
  data() {
    return {
      requestStatus,
      form: {
        old_password: '',
        password: '',
        password_confirmation: '',
      },
      formDefault: {
        old_password: '',
        password: '',
        password_confirmation: '',
      }
    };
  },
  validations: {
    form: {
      old_password: {
        required,
        minLength: minLength(1)
      },
      password: {
        required,
        minLength: minLength(1)
      },
      password_confirmation: {
        required,
        minLength: minLength(1)
      },
    }
  },
  computed: {
    _updatingStatus() {
      return this.$store.state.passwordAndSecurity.updatingStatus;
    },
  },
  methods: {
    async submitForm() {
      this.$v.form.$touch();
      if (this.$v.form.$error) return;

      try {
        const data = await this.$store.dispatch('passwordAndSecurity/resetPassword', this.form);

        if (data.status) {
          console.log(data);
          this.$toasted.success(toasted.text.success);
          this.resetForm();
        }
      } catch (err) {
        console.log(err.response.data.message);
        this.$toasted.error(toasted.text.error);
      }
    },
    resetForm() {
      this.form = {...this.formDefault};
      this.$v.$reset();
    }
  },
};
</script>
<style lang="scss">
.security {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__main {
    max-width: 388px;
    width: 100%;
    padding: 40px 25px;
    background: var(--white-main);
    box-shadow: 0px 3px 10px 3px rgba(43, 72, 105, 0.11);
    border-radius: 8px;
  }


  &__edit-button {
    width: 100px;
    max-width: 100px;
    height: 40px;
  }

  &__two-factor {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__button-space {
    display: flex;
    justify-content: end;
    align-items: center;
  }

  &__form-btn {
    width: 170px;
    height: 40px;
    background: var(--orange-main);
    border-radius: 8px;
    color: var(--white-main);
    border: 1px solid var(--orange-main);
    cursor: pointer;
    font-weight: 600;
    font-size: 14px;
  }
}
</style>